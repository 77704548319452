// 添加线上课程表单校验

export const course = {
    rules: {
        lecturerId: [{required: true, message: '请输入讲师名称', trigger: ['blur', 'change']}],
        courseName: [{required: true, message: '请输入课程名称', trigger: ['blur', 'change']}],
        courseDesc: [{required: true, message: '请输入课程描述', trigger: ['blur', 'change']}],
        courseImg: [{required: true, message: '请输入课程封面图', trigger: ['blur', 'change']}],
        coursePrice: [{required: true, message: '请输入课程原价格', trigger: ['blur', 'change']}],
        courseSortId: [{required: true, message: '请输入课程分类', trigger: ['blur', 'change']}],
        courseActivityPrice: [{required: true, message: '请输入活动价格', trigger: ['blur', 'change']}],
        label: [{required: true, message: '请输入课程标签', trigger: ['blur', 'change']}],
        activityStartTime: [{required: true, message: '请输入课程开始结束时间', trigger: ['blur', 'change']}],
        courseText: [{required: true, message: '请输入课程详情', trigger: ['blur', 'change']}],
    }
}


// 配置管理 配置列表 表单校验

export const config = {
    rules: {
        keyCode: [{required: true, message: '请输入编码', trigger: ['blur', 'change']}],// 编码
        keyName: [{required: true, message: '请输入名称', trigger: ['blur', 'change']}], // 名称
        keyValue: [{required: true, message: '请输入值', trigger: ['blur', 'change']}] // 值
    }
}

// 添加目录
export const catalogue = {
    rules: {
        contentsName: [{required: true, message: '请输入目录名称', trigger: ['blur', 'change']}],// 目录名称
        contentsOrder: [{required: true, message: '请输入目录顺序', trigger: ['blur', 'change']}], // 目录顺序
        videoUrl: [{required: true, message: '请上传视频', trigger: ['blur', 'change']}], // 视频地址
        audioUrl: [{required: true, message: '请上传音频', trigger: ['blur', 'change']}] // 音频地址
    }
}

// 线下课程
export const offline = {
    rules: {
        lecturerId: [{required: true, message: '请选中教师名称', trigger: ['blur', 'change']}],
        courseName: [{required: true, message: '请输入课程名称', trigger: ['blur', 'change']}],
        courseExplain: [{required: true, message: '请输入课程描述', trigger: ['blur', 'change']}],
        courseImg: [{required: true, message: '请上传封面图', trigger: ['blur', 'change']}],
        price: [{required: true, message: '请输入课程价格', trigger: ['blur', 'change']}],
        startTime: [{required: true, message: '请输入课程开始结束时间', trigger: ['blur', 'change']}],
        lastApplyCount: [{required: true, message: '请输入近期报名数量', trigger: ['blur', 'change']}],
        totalApplyCount: [{required: true, message: '请输入总报名数量', trigger: ['blur', 'change']}],
        contactMobile: [{required: true, message: '请输入联系电话', trigger: ['blur', 'change']}],
        courseInfo: [{required: true, message: '请输入课程详情', trigger: ['blur', 'change']}],
    }
}

// 线下课程期数

export const nper = {
    rules: {
        classCity: [{required: true, message: '请输入开课城市', trigger: ['blur', 'change']}],
        issueName: [{required: true, message: '请输入期数名称', trigger: ['blur', 'change']}],
        issueNum: [{required: true, message: '请输入期数号', trigger: ['blur', 'change']}],
        places: [{required: true, message: '请输入本期报名名额', trigger: ['blur', 'change']}],
        totalTicketCount: [{required: true, message: '请输入总票数', trigger: ['blur', 'change']}],
        startTime: [{required: true, message: '请输入开始结束时间', trigger: ['blur', 'change']}],
    }
}
// 商品列表
export const goods = {
    rules: {
        goodsName: [{required: true, message: '请输入商品名称', trigger: ['blur', 'change']}],
        goodsCoverImg: [{required: true, message: '请上传商品封面图', trigger: ['blur', 'change']}],
        goodsDesc: [{required: true, message: '请输入商品描述', trigger: ['blur', 'change']}],
        price: [{required: true, message: '请输入商品价格述', trigger: ['blur', 'change']}],
        vipPrice: [{required: true, message: '请输入商品会员价格', trigger: ['blur', 'change']}],
        stockCount: [{required: true, message: '请输入当前库存	', trigger: ['blur', 'change']}],
        rankSort: [{required: true, message: '请输入排序值', trigger: ['blur', 'change']}],
        goodsImgList: [{required: true, message: '请上传商品轮播图图片', trigger: ['blur', 'change']}],
        goodsInfo: [{required: true, message: '请输入商品详情', trigger: ['blur', 'change']}],
    }
}

// 终端用户列表
export const terminal = {
    rules: {
        nickName: [{required: true, message: '请输入微信昵称', trigger: ['blur', 'change']}],
        phone: [{required: true, message: '请输入用户手机号码', trigger: ['blur', 'change']}],
        imgUrl: [{required: true, message: '请上传用户头像', trigger: ['blur', 'change']}],
        city: [{required: true, message: '请选择地址', trigger: ['blur', 'change']}]
    }
}

// 海报
export const poster = {
    rules: {
        name: [{required: true, message: '请输入海报名称', trigger: ['blur', 'change']}],
        materialImg: [{required: true, message: '请上传海报图片', trigger: ['blur', 'change']}],
        sortNo: [{required: true, message: '请输入排序值', trigger: ['blur', 'change']}],
    }
}
// 主播·
export const anchor = {
    rules: {
        userName: [{required: true, message: '请输入主播名称', trigger: ['blur', 'change']}],
        img: [{required: true, message: '请上传主播头像', trigger: ['blur', 'change']}],
    }
}

// 直播
export const live = {
    rules: {
        liveUserId: [{required: true, message: '请选择主播', trigger: ['blur', 'change']}],
        roomTitle: [{required: true, message: '请输入直播间标题', trigger: ['blur', 'change']}],
        roomCoverImg: [{required: true, message: '请上传直播封面图', trigger: ['blur', 'change']}],
        nextLiveTime: [{required: true, message: '请选择下一场直播时间', trigger: ['blur', 'change']}]
    }
}

// 订单发货
export const deliver = {
    rules: {
        expressName: [{required: true, message: '请输入快递名称', trigger: ['blur', 'change']}],
        expressNum: [{required: true, message: '请输入快递单号', trigger: ['blur', 'change']}],
    }
}