<template>
  <div class="container">
    <el-dialog title="添加直播商品" :visible.sync="teacherShow" width="86%" :before-close="handleClose" top="4vh">
      <div class="content">
        <div class="left">
          <div>
            <goods-list @add="handlerAdd" :list="onLineList" :type="0"/>
          </div>
          <div>
            <goods-list @add="handlerAdd" :list="offlineCourseList" :type="1"/>
          </div>
          <div>
            <goods-list @add="handlerAdd" :list="productList" :type="2"/>
          </div>
        </div>
        <div class="all-product">
          <goods-list :list="liveList" :type="3" @remove="handlerRemove"/>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import goodsList from '@/components/live/productList'
import {offlineCoursePage, coursePage} from '@/apis/course'
import {goodsPage} from '@/apis/goods'
import {liveRoomGoodsList, liveRoomGoodsAdd, liveRoomGoodsDel} from '@/apis/live'

export default {
  name: 'setProduct',
  props: {
    teacherShow: {
      type: Boolean,
      default: false
    },
    roomNo: Number | String
  },
  components: {goodsList},
  emits: ['close'],
  data() {
    return {
      offlineCourseList: [],
      onLineList: [],
      productList: [],
      liveList: []
    };
  },
  created() {
    this.getofflineCoursePage()
    this.getOnLine()
    this.getProduct()
    this.getLiveRoomGoodsList()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    // 移除商品
    async handlerRemove(id) {
      await liveRoomGoodsDel({id})
      const index = this.liveList.findIndex(item => item.id)
      this.liveList.splice(index, 1)
      this.$message.success('移除成功')
    },
    // 添加直播间商品
    async handlerAdd(item, productType) {
      await liveRoomGoodsAdd({productUniId: item.id, productType, roomNo: this.roomNo})
      this.getLiveRoomGoodsList()
      this.$message.success('添加成功')
    },
    async getofflineCoursePage() {
      const res = await offlineCoursePage({size: 99})
      this.offlineCourseList = res.data.records
    },
    async getOnLine() {
      const res = await coursePage({size: 99})
      this.onLineList = res.data.records
    },
    async getProduct() {
      const res = await goodsPage({size: 99})
      this.productList = res.data.records
    },
    // 获取直播商品列表
    async getLiveRoomGoodsList() {
      const res = await liveRoomGoodsList({roomNo: this.roomNo})
      this.liveList = res.data
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;

  /deep/ .el-dialog__body,
  /deep/ .el-dialog {
    height: 90%;
    padding: 0;
  }

  .content {
    display: flex;
    height: 100%;
    width: 100%;

    .left {
      display: flex;
      width: 70%;
      height: 100%;

      > div {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: 100%;
        border: 1px dashed #eee;
      }
    }

    .all-product {
      flex: 1;
      height: 100%;
      padding: 0 20px;
    }
  }
}
</style>