<template>
  <div class="container">
    <el-card class="box-card">
      <el-button type="primary" size="small" @click="teacherShow = true">添加直播</el-button>
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="id" prop="id"></el-table-column>
        <el-table-column align="center" label="直播间标题" prop="roomTitle"></el-table-column>
        <el-table-column align="center" label="直播间房号" prop="roomNo"></el-table-column>
        <el-table-column align="center" label="直播间封面图">
          <template slot-scope="scope">
            <el-image :previewSrcList="[scope.row.roomCoverImg]" style="width: 100px;" :src="scope.row.roomCoverImg"
                      fit="fill"></el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="横/竖屏">
          <template slot-scope="scope">
            <span v-if="scope.row.screenMode == 0" style="color: #409eff">
                横屏
            </span>
            <span v-else style="color: #42b983">
                竖屏
            </span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="分享海报" prop="sharePosterImg">
          <template slot-scope="scope">
            <div class="poster-box">
              <el-tag v-if="!scope.row.sharePosterImg" type="danger" disable-transitions>未上传</el-tag>
              <span class="look-poster" v-else @click="lookPosterImg(scope.row.sharePosterImg)" v-popover:popoverPoster>查看</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="下场直播时间">
          <template slot-scope="scope">
            <span v-if="scope.row.nextLiveTime == null" style="color: #8c939d;font-size: 0.8em">
                暂无安排
            </span>
            <span v-else style="color: #42b983">
                {{ scope.row.nextLiveTime }}
            </span>
          </template>
        </el-table-column>

        <el-table-column align="center" label="开启微信通知">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isSubscribeNotify == 0 ? 'danger' : 'success'" disable-transitions>
              {{ scope.row.isSubscribeNotify == 0 ? '关闭' : '开启' }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column align="center" label="置顶">
          <template slot-scope="scope">
            <el-tag :type="scope.row.isTop == 0 ? 'danger' : 'success'" disable-transitions>
              {{ scope.row.isTop == 0 ? '否' : '是' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status == 0 ? 'danger' : scope.row.status == 1 ? 'success ' : 'warning'"
                    disable-transitions>
              {{ scope.row.status == 0 ? '未开播' : scope.row.status == 1 ? '开播中 ' : '禁播' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="排序值" prop="orderSort"></el-table-column>
        <el-table-column align="center" label="操作" width="400">
          <template slot-scope="scope">
            <el-button type="success" @click="addProduct(scope.row.roomNo)" icon="el-icon-edit" size="mini">添加商品
            </el-button>
            <el-button type="success" @click="start(scope.row)" icon="el-icon-edit" size="mini">立即开播</el-button>
            <el-button type="primary" @click="handlerEdit(scope.row)" icon="el-icon-edit" size="mini">编辑</el-button>
            <el-button type="danger" @click="handlerRemove(scope.row.id)" icon="el-icon-delete" size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <el-dialog top="10vh" :title="form.id ? '编辑直播' : '添加直播'" :visible.sync="teacherShow" width="40%"
               :before-close="handleClose">
      <el-form :model="form" ref="form" label-width="120px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="主播名称:" label-width="120px" prop="liveUserId">
          <el-select v-model="form.liveUserId" placeholder="请选择主播" @focus="clearValidate('liveUserId')">
            <el-option v-for="item in hostList" :key="item.value" :label="item.userName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直播标题:" prop="roomTitle">
          <el-input @focus="clearValidate('roomTitle')" v-model="form.roomTitle" placeholder="请输入直播标题"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="直播封面:" label-width="120px" prop="roomCoverImg">
          <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                     :on-success="successImages">
            <img v-if="form.roomCoverImg" :src="form.roomCoverImg" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="海报模板:" label-width="120px">
          <el-upload class="avatar-uploader" :show-file-list="false" action="/api/file/upload"
                     :on-success="successPosterImages">
            <img v-if="form.sharePosterImg" :src="form.sharePosterImg" class="avatar-poster">
            <i v-else class="el-icon-plus avatar-uploader-icon layout_sa"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="下场直播时间:" style="margin-top:10px;" prop="nextLiveTime">
          <el-date-picker
              v-model="form.nextLiveTime"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetime"
              placeholder="选择下一次开播时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="横/竖屏:" style="margin-top:10px;" prop="screenMode">
          <el-radio-group v-model="form.screenMode">
            <el-radio :label="1">竖屏</el-radio>
            <el-radio :label="0">横屏</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="微信通知:" style="margin-top:10px;" prop="isSubscribeNotify">
          <el-radio-group v-model="form.isSubscribeNotify">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="开启置顶:" style="margin-top:10px;" prop="isPlaced">
          <el-switch v-model="isPlaced" active-text="开启" inactive-text="关闭"></el-switch>
        </el-form-item>

        <el-form-item label="排序值:" prop="orderSort">
          <el-input @focus="clearValidate('orderSort')" type="number" v-model="form.orderSort" placeholder="请输入排序值"
                    autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item label="状态:" style="margin-top:10px;">
          <el-radio-group v-model="form.status">
            <el-radio v-for="item in radioList" :key="item.label" :label="item.label">{{ item.value }}</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="handleClose">取 消</el-button>
        <el-button size="mini" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加直播间商品 -->
    <template v-if="addLiveProductShow">
      <setProduct @close="addLiveProductShow = false" :roomNo="addLiveProductCruuentRoomNo"
                  :teacherShow="addLiveProductShow"/>
    </template>

    <el-dialog
        title="查看海报模板"
        :visible.sync="lookPosterImgShow"
        width="30%"
        :before-close="posterImgHandleClose">
      <div>
        <img :src="currentPosterImg" width="220px" height="420px" style="display: block; margin: 0 auto;"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="lookPosterImgShow = false">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import {liveRoomPage, liveRoomAdd, liveRoomDel, liveUserPage, liveRoomSet} from '@/apis/live'
import {live} from '@/utils/rules'
import setProduct from '@/components/live/setProduct'

export default {
  components: {setProduct},
  data() {
    return {
      lookPosterImgShow: false,
      currentPosterImg: '',
      query: {
        current: 1,
        size: 10,
      },
      tableData: [],
      loading: false,
      total: null,
      teacherShow: false, // 添加对话框
      rules: live.rules,
      form: {
        liveUserId: '', // 主播id
        roomTitle: '', // 直播间标题
        roomCoverImg: '',  // 直播间封面图
        screenMode: 1,
        sharePosterImg: null,
        isSubscribeNotify: 1,
        orderSort: 1,
        nextLiveTime: null,
        lastLiveTime: null,
        lastCloseLiveTime: null,
        isPushOss: 1, // 推送到阿里云oss(0 = 否 1 = 是)
        isTop: '',  // 开启置顶(0 = 否 1 = 是)
        status: 0, // 状态(0 = 未开播 1 = 开播中 - 1=禁播)
      },
      radioList: [{
        value: "未开播",
        label: 0,
      }, {
        value: "开播中",
        label: 1,
      }, {
        value: "禁播",
        label: -1,
      }],
      isPlaced: true,
      hostList: [],
      addLiveProductShow: false,
      addLiveProductCruuentRoomNo: null
    };
  },
  created() {
    this.getData()
    this.getliveUserPage()
  },
  methods: {
    posterImgHandleClose() {
      this.lookPosterImgShow = false;
    },
    lookPosterImg(img) {
      this.currentPosterImg = img;
      this.lookPosterImgShow = true;
    },
    // 添加直播间商品
    addProduct(roomNo) {
      this.addLiveProductCruuentRoomNo = roomNo
      this.addLiveProductShow = true
    },

    start(item) {
      this.$router.push({
        path: '/live',
        query: {id: item.id, roomNo: item.roomNo, roomTitle: item.roomTitle}
      })
    },
    submit() {
      this.$refs.form.validate(async (val) => {
        if (!val) return;
        this.form.isTop = this.isPlaced ? 1 : 0
        this.form.id ? await liveRoomSet(this.form) : await liveRoomAdd(this.form)
        this.$message({
          message: this.form.id ? "编辑成功" : "添加成功",
          type: "success",
        });
        this.teacherShow = false;
        this.getData();
        this.clearCen();
      });
    },
    handlerRemove(id) {
      this.$confirm("此操作将永久删除直播, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(async () => {
            await liveRoomDel({id});
            this.query.current = 1;
            this.$message({message: "删除成功", type: "success"});
            this.getData();
          })
          .catch((err) => {
          });
    },
    // 编辑线上课程
    async handlerEdit(item) {
      this.teacherShow = true;
      const {isTop, isPushOss} = item;
      this.isPushCloud = isPushOss === 1 ? true : false;
      this.isPlaced = isTop === 1 ? true : false;
      this.form = {...item}
    },
    handleClose() {
      this.teacherShow = false;
      this.clearCen();
    },
    handleSizeChange(size) {
      this.query.size = size
      this.getData()
    },
    handleCurrentChange(page) {
      this.query.current = page
      this.getData()
    },
    async getData() {
      this.loading = true
      const res = await liveRoomPage(this.query)
      const {records, total, current, size} = res.data
      this.tableData = records
      this.total = total
      this.query.current = current
      this.query.size = size
      setTimeout(() => {
        this.loading = false
      }, 50)
    },
    async getliveUserPage() {
      const res = await liveUserPage({size: 10000})
      this.hostList = res.data.records
    },
    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.form).map((key) => (this.form[key] = ""));
      this.isAuditionOpen = true;
      this.isPlaced = true;
      this.form.status = 0
      delete this.form.id; // 删除对象里面的id
    },
    // 上传图片成功的钩子函数
    successImages(response, file, fileList) {
      this.clearValidate('roomCoverImg')
      this.form.roomCoverImg = response.data;
    },
    successPosterImages(response, file, fileList) {
      this.form.sharePosterImg = response.data;
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
  },
};
</script>

<style lang="less" scoped>
.el-form-item {
  width: 70%;
}

.poster-box {
  display: flex;
  justify-content: center;
  align-items: center;

  .look-poster {
    padding: 0 10px;
    font-size: .9em;
    color: #409eff;
    text-decoration: underline;
  }

  .look-poster:hover {
    cursor: pointer;
    font-weight: bold;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 100px;
  border: 1px dashed #999;
}

.avatar {
  width: 200px;
  height: 100px;
  display: block;
}

.avatar-poster {
  width: 90px;
  height: 160px;
  display: block;
}
</style>