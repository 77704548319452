// 商品管理
import axios from 'axios'
import QS from 'qs'

// 获取商品列表
export const goodsPage = params => {
    return axios.post(`/api/goods/page`, QS.stringify(params)).then(res => res.data)
}
// 添加或编辑商品
export const addOnSet = params => {
    return axios.post(`/api/goods/addOnSet`, QS.stringify(params)).then(res => res.data)
}
// 删除商品
export const delGoods = params => {
    return axios.post(`/api/goods/del`, QS.stringify(params)).then(res => res.data)
}
// 商品详情
export const goodsImfo = params => {
    return axios.post(`/api/goods/goodsId`, QS.stringify(params)).then(res => res.data)
}