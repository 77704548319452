<template>
  <div class="container">
    <h2>{{ titleList[type] }}</h2>
    <div class="list">
      <div class="list-item layout_sb" v-for="item in list" :key="item.id">
        <div class="product">
          <el-image style="width: 80px; height: 80px"
                    :src="`${item[imgSrcName[type]]}?x-oss-process=image/resize,m_fill,w_200,h_200`"
                    fit="cover"></el-image>
          <div class="product-info layout_sb">
            <div class="overflow-2">{{ item[productName[type]] }}</div>
            <!-- 判断如果是免费类型的课程 或者是 当前直播商品价格为空 显示免费 -->
            <template v-if="(type == 0 && item.type == 0) || (type == 3 && !item.price)">
              <span style="color:orange;">免费</span>
            </template>
            <!-- 显示线上课程是否活动 -->
            <template v-else-if="type == 0 && item.isActivity == 0">
              <div style="color:orange;">活动价格<span
                  style="color:red;margin-left:10px;font-weight: 600;">￥{{ item.courseActivityPrice.toFixed(2) }}</span>
              </div>
            </template>
            <div v-else>价格<span
                style="color:red;margin-left:10px;font-weight: 600;">￥{{ type == 0 ? item.coursePrice.toFixed(2) : item.price.toFixed(2) }}</span>
            </div>
          </div>
        </div>
        <el-button v-if="type == 3" class="list-btn layout" size="mini" type="danger" @click="$emit('remove',item.id)">
          移除
        </el-button>
        <el-button v-else class="list-btn layout" size="mini" type="primary" @click="$emit('add',item,type)">添加
        </el-button>
      </div>
      <div class="layout" style="margin-top:10px;">没有更多了</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
    type: Number | String //  0=线上课程 1=线下课程 2=实体商品 3 当前直播间商品
  },
  emits: ['remove', 'add'],
  data() {
    return {
      titleList: ['线上课程', '线下课程', '实体商品', '当前直播间商品'],
      imgSrcName: ['courseImg', 'courseImg', 'goodsCoverImg', 'img'],
      productName: ['courseName', 'courseName', 'goodsName', 'name'],
      productProce: ['coursePrice', 'price', 'price', 'price']
    };
  },
  mounted() {
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  overflow-y: auto;

  h2 {
    display: flex;
    align-items: center;
    height: 50px;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99;
    padding-left: 20px;
  }
}

.list {
  flex: 1;
  padding: 0 20px;

  .list-item {
    align-items: flex-start;
    height: 120px;
    padding: 20px 0;
    border-bottom: 1px solid #eee;

    .product {
      display: flex;
      flex: 1;
      height: 100%;

      .product-info {
        flex: 1;
        flex-direction: column;
        align-items: flex-start;
        padding: 0 10px;
      }
    }

    .list-btn {
      width: 50px;
    }
  }
}
</style>